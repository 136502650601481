<template>
  <div class="timer-nav" v-if="this.timerRunning">
    Page Refresh In: {{ formattedTime }}
  </div>
</template>
<script>
import nav from '@/_nav.js'
import axios from "axios"
import vueCookies from 'vue-cookies'
export default {
  name: 'AppTimerPage',
  data: () => {
    return {
      APIHOST: process.env.VUE_APP_APIS_HOST === undefined ? '' : process.env.VUE_APP_APIS_HOST,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': vueCookies.get('accessToken'),
      },
      timerRunning: false,
      timeLeft: 10, // 20 minutes in seconds
      timerInterval: null,
      timerForPage: {}
    }
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
  },
  methods: {
    getNavFresh(path) {
      axios.get(this.APIHOST + '/inbound/timer/', { headers: this.headers })
        .then(response => {
          let userTimerList = response.data.response
          this.getNav(userTimerList, path)
        })
        .catch(error => {
          alert(JSON.stringify(error.response?.data))
        })
    },
    getNav(userTimerList, path) {
      nav.map(item => {
        if (item?.to) {
          if (item?.to.includes(path)) {
            userTimerList?.map(timer => {
              if (timer.page_name === item.name) {
                const val = timer.minutes_to_refresh * 60 * 1000
                const val2 = timer.minutes_to_refresh * 60
                this.timerForPage = { time: val2, path: path },
                  this.startTimer()
                setTimeout(() => {
                  location.reload()
                }, val)
              }
            })
          }
        }
      })
    },
    startTimer() {
      this.timeLeft = this.timerForPage?.time
      this.timerRunning = true;
      this.timerInterval = setInterval(() => {
        if (this.timeLeft > 0 && this.timerForPage?.path === this.$route.path) {
          this.timeLeft--;
        } else {
          clearInterval(this.timerInterval);
          this.timerRunning = false;
        }
      }, 1000); // Update every second
    },
  },
  mounted() {
    this.getNavFresh(this.$route.path)
  },
}
</script>