import { defineComponent, h, onMounted, ref, resolveComponent, getCurrentInstance } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue-pro'
import nav from '@/_nav.js'
import vueCookies from 'vue-cookies'
import axios from "axios"


const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }
  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  emits: ['getNavFresh'],
  setup(props, { emit }) {
    const route = useRoute()
    const firstRender = ref(true)
    const order_val = ref({})

    const instance = getCurrentInstance();
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': vueCookies.get('accessToken')
    }
    let APIHOST = process.env.VUE_APP_APIS_HOST === undefined ? '' : process.env.VUE_APP_APIS_HOST
    const handleCount = () => {
      axios.post(APIHOST + '/infra/getNewCount/', JSON.stringify({
        portal: 'Warehouse',
      }), {
        headers: headers,
      })
        .then(response => {
          order_val.value = response.data.response
        })
    }
    onMounted(() => {
      APIHOST = process.env.VUE_APP_APIS_HOST === undefined ? '' : process.env.VUE_APP_APIS_HOST
      if (APIHOST?.length) {
        handleCount()
      }
      firstRender.value = false
    })
    const renderItem = (item) => {
      console.log(item, '==========');
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: 'nav-icon',
                name: item.icon,
              }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }

      return item.to
        ? h(
          RouterLink,
          {
            to: item.to,
            custom: true,
            onClick: () => { emit('getNavFresh', item.to) },
          },
          {
            // this.$emit('getNavFresh')
            default: (props) =>
              h(
                resolveComponent(item.component),
                {
                  active: props.isActive,
                  href: props.href,
                  onClick: () => { props.navigate(); handleCount(); emit('getNavFresh', item.to) },
                },
                {
                  default: () => [
                    item.icon &&
                    item.icon === 'cil-apps' ? <Inventory /> :

                    h(resolveComponent('CIcon'), {
                      customClassName: 'nav-icon',
                      name: item.icon,
                    }),
                    
                    <div>{item.name}
                      {item.countBadge && order_val.value[item?.countBadgeparam] ? route.path === item?.to ? null:<CBadge class='ms-2' color="success" shape="rounded-circle">{JSON.stringify(order_val.value[item?.countBadgeparam])}</CBadge> : null}
                    </div>,
                    item.badge &&
                    h(
                      CBadge,
                      {
                        class: 'ms-auto',
                        color: item.badge.color,
                      },
                      {
                        default: () => item.badge.text,
                      },
                    ),
                  ],
                },
              ),
          },
        )
        : h(
          resolveComponent(item.component),
          {},
          {
            default: () => item.name,
          },
        )
    }
    const vnode = h(
      CSidebarNav,
      {},
      {
        default: () => nav.map((item) => renderItem(item)),
      },
    )
    return {emit, vnode}
      
  },
  render() {
    return this.vnode;
  }
})
export { AppSidebarNav }
